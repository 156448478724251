import {FingerPrintSharp, GlobeSharp, ShapesSharp} from "react-ionicons";
import React from "react";
import {RootState} from "../../store";
import {connect, ConnectedProps} from "react-redux";
import {useAuthStatusQuery} from "../../services/hsoid";
import {Link} from "react-router-dom";

const mapState = (state: RootState) => ({
  authentication: state
});

type PropsFromRedux = ConnectedProps<typeof connector>

type Props = PropsFromRedux;

const HomeComponent = (props: Props) => {
    const {data, error, isLoading} = useAuthStatusQuery();
    return(
        <div className="container">
                <div className="content">

                    <div className="columns mt-6">
                        <div className="column">
                            <p className="is-black">Welcome {data?.user.firstname}</p>
                        </div>
                    </div>
                </div>
                <div className="fixed-grid has-3-cols mt-5">
                <div className="grid">
                    <div className="cell">
                        <article className="custombox">
                            <p className="title"><Link to={"/mfa"} style={{ color: '#000000' }}><FingerPrintSharp color={'#6B33EF'} /> MFA</Link></p>
                            <div className="orange-line"></div>
                            <p className="content is-black">Manage Multi-Factor Authentication settings on your account.</p>
                        </article>
                    </div>
                    <div className="cell">
                        <article className="custombox">
                            <p className="title"><Link to={"/clients"} style={{ color: '#000000' }}><ShapesSharp color={'#6B33EF'} /> API Clients</Link></p>
                            <div className="orange-line"></div>
                            <p className="content is-black">Manage OAuth2 API clients in order to authenticate to the hSo APIs.</p>
                        </article>
                    </div>
                    <div className="cell">
                        <article className="custombox">
                            <p className="title"><a href={"https://portal.hso.co.uk"} style={{ color: "black" }}><GlobeSharp color={'#6B33EF'} /> Portal</a></p>
                            <div className="orange-line"></div>
                            <div className="content is-black">
                                <p>Return to the customer portal.</p>
                            </div>
                        </article>
                    </div>
                </div>
            </div>
        </div>
    )
}

const connector = connect(mapState);

export default connector(HomeComponent);